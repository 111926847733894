.quote-list-tab {
	text-align: center;		
 }
 .quote-list-tab .tabs {
	border-radius: 10px;
	display: inline-block;
	border: 1px solid #1d42d9;
	margin-bottom: 30px;
	
}

.quote-list-tab .tabs li {
	display: inline-block;
	padding: 0 10px;
	/*font-family: $heading-font-family;*/
	color: #fff;
	font-size: 20px;
	font-weight: 700;
	padding: 10px;
	
}
.quote-list-tab .tabs li a {
	color: #1d42d9;
	padding: 10px 20px;
}
.quote-list-tab .tabs li.current {
	background-color: #1d42d9;
	border-radius: 10px;	
}

.quote-list-tab .tabs li.current a {
	color: #fff;
}
.tab .tabs_item {
    display: none;
  }
.tab .tabs_item:first-child {
    display: block;
}
a{
	cursor:pointer;
}