.navbarCustom {
    background-color: #005ecb !important;
    min-height: 80px;
    font-family: "Poppins", "Helvetica", "Arial", sans-serif;
    padding: .5rem 1rem;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: .6px;
    color: #000;
    border-radius: 0;
    border-width: 0;
    border-style: solid;
    border-color: transparent;
    transition: all .3s ease;
    line-height: normal;
}

.navbarBrand {
    /* overflow: hidden; */
    /* position: relative; */
    width: 100%;
    display: flex;
    padding: 0;
}

.logo {
    height: 100%;
    width: 100%
    /* width: 50%; */
    /* position: absolute; */
    /* left: 0;
    top: 0; */
    /* padding: 1rem; */
}

.nav-link a {
    font-size: 12px;
    /* line-height: 14px; */
    font-weight: 600;
    font-style: normal;
    color: inherit;
    font-family: "Poppins", "Helvetica", "Arial", sans-serif;
    padding: 12px 6px;
    display: block;
    text-transform: uppercase;
    transition: 0.2s

}

.nav-link a:hover {
    color: inherit;
}

.nav-item.nav-link{
  color: #000 !important;
}

.nav-item.nav-link:hover{
  color: #fff !important;
}

.dropdown-menu a {
    font-size: 12px !important;
    color: #000 !important;
    line-height: 14px;
    font-weight: 500;
    font-style: normal;
    font-family: "Poppins", "Helvetica", "Arial", sans-serif;
    padding: 12px 20px 10px 18px;
    display: block;
    text-transform: none;
    /* transition: .3s ease; */
}

.dropdown-menu a:hover {
    color: #005ecb !important;
    background-color: #fff !important;
}

#collasible-nav-dropdown {
  color: #000 !important;
}

#collasible-nav-dropdown:hover {
  color: #fff !important;
}


.navbar-brand {
    transform: translateX(-50%);
    left: 50%;
    top:0;
    position: absolute;
    height: 80px; 
    width: 80px
  }

  #logo {
    height: 100%;
  }

  .dropdown-item {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

/* Upto Large devices (laptops/desktops, 992px and down) */

@media screen and (min-width: 992px) {

    .dropdown-menu{
        /* margin-top: 10px !important; */
        border-radius: 5px;
        border-top: 10px solid transparent;
        background-clip: padding-box;
        /* background-color: #f1f1f1; */
      
    }

    .dropdown:hover .dropdown-menu {
      display: block;
      /* margin-top: 10px !important; */
    }
    .dropdown .dropdown-menu {
      display: none;
    }
    .dropdown:hover .dropdown-toggle::after {
        border-bottom: 0.3em solid;
        border-top: 0;
      }

    .nav-item::after {
        content: '';
        display: block;
        width: 0px;
        height: 2px;
        background: #ffffff;
        transition: 0.4s
    }
    
    .nav-item:hover::after {
        width: 100%
    }

    .navbar-ghost-space {
        transform: translateX(-50%);
        left: 50%;
        top:0;
        width: 100px
      }

  }



@media only screen and (max-width: 991px) {
    .navbar-nav:first-of-type{
        margin-top: 70px;
    }

    .dropdown-toggle.show::after {
        border-top: 0;
        border-bottom: 0.3em solid;
      }

}
